.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*-- NAVIGATION BAR --*/
.navbarContainer {
  background-color:#ffffff;
  position: fixed;
  height: auto;
}

.navbarContainer a {
  display: inline;
	text-align: left;
	padding: 14px 7px;
	text-decoration: none;
	color: rgb(250, 111, 111);
	line-height: 40px;
	font-size: 18px;
}

.navbarContainer a:hover {
  color:#3b333b
}

.logo {
  width: 220px;
  padding-left: 7%;
 
}

/*-- FOOTER --*/
.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ff5d4b;
  color: rgb(0, 0, 0);
  text-align: center;
  padding-top: 4%;
  padding-bottom: 4%;
}

/*-- MAIN CONTENT --*/

.home, .projects, .about, .contact, .requestForm {
  text-align: center;
}

.-desktop-homeImage {
  width: 100%;
  object-fit: scale-down;
}

.homeSloganText {
  width: 500px;
  position: absolute;
  bottom: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
}

.-div-requestButton {
  position: absolute;
  bottom: 45%;
  right: 5%;
  padding-left: 80%;
  padding-bottom: 5%;
}

.servicesSection {
  text-align: center;
  padding-top: 5%;
  padding-left: 7%;
  padding-right: 7%;
}

.servicesIcons {
  padding-top: 7%;
  width: 100px;
  align-items: center;
  justify-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
 
}

.servicesTextSection1, .servicesTextSection3 {
  width: 40%;
  height: 300px;
  border: 0.1px solid;
  padding: 30px;
  padding-top: 3%;
  position: absolute;
  left: auto;
  border-color: #dad8d8;
  box-shadow: 2px 2px 15px 1px rgb(233, 231, 231);
}

.servicesTextSection2, .servicesTextSection4  {
  width: 46.1%;
  height: 300px;
  border: 0.1px solid;
  padding: 30px;
  padding-top: 3%;
  position: relative;
  left: 53.44%;
  border-color: #dad8d8;
  box-shadow: 2px 2px 15px 1px rgb(233, 231, 231);
}

/*-- BUTTONS --*/

.requestButton {
  color: #ffffff;
  background-color: #fb6161;
  font-weight: 600;
  border-radius: 0px;
  border: 0px;
  padding: 20px;
  bottom: 10x;
  transition: 0.5s;
}

.requestButton:hover {
  background-color: #4a404a;
}

.requestForm .sendRequestButton {
  display: inline-block;
  color: #ffffff;
  background-color: #fb6161;
  font-weight: 600;
  border: 0px;
  padding: 20px;
  bottom: 10x;
  transition: 0.5s;
  align-items: center;
  justify-content: center;
}

.sendRequestButton:hover {
  background-color: #4a404a;
}

/*-- IMAGES --*/

.-tablet-homeImage {
  width: 0px;
  height: 0px;
}

.-mobile-homeImage {
  width: 0px;
  height: 0px;
}

.headingImage {
  width: 170px;
  height: auto;
}


.homeTextSectionLight {
  background-color: #4a404a;
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 10%;
  padding-right: 10%;
  transition: 0.5s;
}

.homeTextSectionLight:hover {
  background-color: #fc7869;
}

.homeTextSectionDark {
  background-color: #3b333b;
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 10%;
  padding-right: 10%;
  transition: 0.5s;
}

.homeTextSectionDark:hover {
  background-color: #fc7869;
}

.homeTextSectionWhite {
  padding-top: 2%;
  padding-left: 10%;
  padding-right: 10%;
 
}

.headingSection {
  text-align: center;
  padding-top: 5%;
}

.projectSection {
  padding-top: 3%;
  padding-left: 7%;
  padding-right: 7%;
}

.textSection {
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 10%;
  padding-right: 10%;
  border: 0.1px solid;
  padding: 20px;
  border-color: #dad8d8;
  box-shadow: 2px 2px 15px 1px rgb(233, 231, 231);
}

.textSpace {
  padding-top: 5%;
}

.-mobile-textSpace {
  width: 0px;
  height: 0px;
}

.requestButtonText {
  text-align: center;
}

.buttons {
  text-align: center;
  padding-top: 1%;
  padding-bottom: 10%;
  font-family:sans-serif
}

/*-- SOCIAL ICONS --*/

.socialIcons {
  display: inline-grid;
  grid-template-columns: 1fr 1.77fr 1fr;

}

.twitterIcon {
  position: relative;

  transition: 0.5s;
}

.linkedInIcon {
  position: relative;
  transition: 0.5s;
}

.instaIcon {
  position: relative;
  transition: 0.5s;
}

.twitterIconButton {
  background-color: #fb6161;
  width: 40px;
  border-radius: 0px;
  border: 0px;
  padding: 3px;
  bottom: 10x;
  transition: 0.5s;
}

.twitterIconImage {
  width: 100%;
}

.linkedInIconButton {
  background-color: #fb6161;
  width: 40px;
  border-radius: 0px;
  border: 0px;
  padding: 3px;
  bottom: 10x;
  transition: 0.5s;
}

.linkedInIconImage {
  width: 100%;
}

.instaIconButton {
  background-color: #fb6161;
  width: 40px;
  border-radius: 0px;
  border: 0px;
  padding: 2px;
  bottom: 10x;
  transition: 0.5s;
}

.instaIconImage {
  width: 100%;
}

.twitterIcon :hover {
  background-color: #4a404a;
}

.linkedInIcon :hover {
  background-color: #4a404a;
}

.instaIcon :hover {
  background-color: #4a404a;
}

/*-- REQUEST FORM --*/

.formTextSpace {
  padding-top: 1%;
}

.formMessage {
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 1%;
  padding-bottom: 2%;
}

.requestForm {
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color:#f5f5f5;
  font-family:sans-serif
}

input[type=text] {
  width: 500px;
  height: 10%;
  padding: 12px 20px;
  margin:8px 0;
  border: #dad8d8;
}

textarea {
  width: 500px;
  height: 190px;
  padding: 12px 20px;
  margin:8px 0;
  border: #dad8d8;
}

::placeholder {
  color: #000000;
  font-weight: 250;
}

.confirmationMessage {
  display: none;
}

/*-- CONFIRMATION PAGE --*/

.requestConfirmation {
  background-color: #3b333b;
  display: inline-block;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mobileRequestConfirmationText {
    width: 0%;
}

.requestConfirmationText {
  display: inline-block;
  width: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.confirmationHomeSection {
  display: inline-block;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.confirmationHomeSection .returnHome {
  width: 30%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.homeButton:hover {
  background-color: #4a404a;
}

/*-- TEXTS --*/

h1 {
  color: #ffffff;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem !important;
}

h2 {
  color: #fb6161;
  font-family:unset;
  font-size: 2.3rem !important;
  transition: 0.5s;
}

h3 {
  color:#3b333b;
  transition: 0.5s;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.3rem !important;
}

h4 {
    color:#fb6161;
    font-family:unset;
    font-size: 1.7rem !important;
}

p1 {
  color: #ffffff;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  transition: 0.5s;
}

p2 {
  color: #dad8d8;
  font-family: inherit;
  transition: 0.5s;
}

p3 {
  color: #646464;
  font-family: inherit;
}

p4 {
  color: #7f7f7f;
}

/*-- MOBILE SCREEN RESPONSE --*/

@media only screen and (max-width: 1400px) {
  .logo {
    width: 200px;
    padding-left: 7%;
    transition: 0.9s;
  }
}

@media only screen and (max-width: 1200px) {
  .logo {
    width: 190px;
    padding-left: 7%; 
    transition: 0.9s;
  }

  .homeSloganText {
    padding-left: 5%;
    padding-bottom: 2%;
    transition: 0.9s;

  }

  .-div-requestButton {
    position: absolute;
    bottom: 45%;
    right: 5%;
    padding-left: 70%;
    padding-bottom: 5%;
    transition: 0.9s;
  }
}

@media only screen and (max-width: 990px) {
  .logo {
    width: 170px;
    padding-left: 7%; 
    transition: 0.9s;
  }

  .homeSloganText {
    padding-left: 10%;
    padding-bottom: 5%;
    transition: 0.9s;
  }

  .-mobile-textSpace {
    padding-top: 5%;
  }

  .servicesTextSection1, .servicesTextSection3 {
    position: relative;
    padding-top: 6%;
    width: 90%;
    left: 5%;

  }

  .servicesTextSection2, .servicesTextSection4 {
    position: relative;
    padding-top: 6%;
    width: 90%;
    left: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .logo {
    width: 150px;
    padding-left: 7%; 
    transition: 0.9s;
  }

  .-desktop-homeImage {
    display: none;
    transition: 0.5s;
  }

  .-tablet-homeImage {
    width: 100%;
    height: auto;
    transition: 0.5s;
  }

  .homeSloganText {
    padding-left: 20%;
    padding-bottom: 11%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
  }

  .headingSection {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 5%;
    padding-right: 5%;
    transition: 0.5s;
  }

  h2 {
    font-size: 1.5rem !important;
  }

  input[type=text] {
    width: 400px;
 
  }
  
  textarea {
    width: 400px;
    height: 200px;
  }

  .requestConfirmationText {
    width: 0%;
  }

  .requestConfirmation {
    width: 100%;
    height: 20%;
  }

  .mobileRequestConfirmationText {
    width: 100%;
  }

  .mobileTextSpace {
    padding-top: 3%;
  }
}

@media only screen and (max-width: 500px) {
  .logo {
    width: 115px;
    padding-left: 7%; 
  } 

  .-desktop-homeImage{
    display: none;
  }

  .-tablet-homeImage{
    display: none;
  }

  .-mobile-homeImage {
    width: 100%;
    height: auto;
  }

  input[type=text] {
    width: 300px;
  }
  
  textarea {
    width: 300px;
  }

  .requestConfirmation {
    height: 20%;
  
  }

  .requestConfirmationText {
    padding-top: 140%;
    width: 0%;
  }

  .headingSection {
    padding-top: 7%;
  }

  .mobileTextSpace {
    padding-top: 5%;
  }

  h1 {
    font-size: 1.3rem !important;
  }

  h4 {
    font-size: 1.4rem !important;
  }

  p2 {
    font-size: 0.95rem !important;
  }
}